import { HttpClient } from '@angular/common/http';
import { Component,ElementRef,Inject,Input,OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from 'bootstrap';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
//import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

import * as jspdf from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'notifications-cmp',
    moduleId: module.id,
    templateUrl: 'notifications.component.html'
})

export class NotificationsComponent{
  title = 'htmltopdf';
  
  data_ordre:any=[];
  base_url : any='https://africantechnolab.com';
  constructor( private datePipe : DatePipe, private router: Router,
    private http : HttpClient,
    private modalService : NgbModal,

  
    private toastr: ToastrService)
    {

this.liste_mission();

    }
  showNotification(from, align) {
    const color = Math.floor(Math.random() * 5 + 1);

    switch (color) {
      case 1:
        this.toastr.info(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">Welcome to <b>Paper Dashboard Angular</b> - a beautiful bootstrap dashboard for every web developer.</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-info alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      case 2:
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">Welcome to <b>Paper Dashboard Angular</b> - a beautiful bootstrap dashboard for every web developer.</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      case 3:
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">Welcome to <b>Paper Dashboard Angular</b> - a beautiful bootstrap dashboard for every web developer.</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      case 4:
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">Welcome to <b>Paper Dashboard Angular</b> - a beautiful bootstrap dashboard for every web developer.</span>',
          "",
          {
            timeOut: 4000,
            enableHtml: true,
            closeButton: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      case 5:
        this.toastr.show(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">Welcome to <b>Paper Dashboard Angular</b> - a beautiful bootstrap dashboard for every web developer.</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-primary alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      default:
        break;
    }
  }

  creer_mission()
  {
      const modalRef = this.modalService.open(modalCreer_ordremission);
      modalRef.result.then(
          (result) => {
       this.liste_mission();
          },
          (reason) => {
             
             if(reason==undefined)
             {
              this.liste_mission()
             }
          }
        );
  }
  supprimer(id_ordre)
  {
    
    Swal.fire({
      title: 'Voulez vous vraiment Supprimer ?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Oui',
      denyButtonText: `Non`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.http.get(this.base_url+'/suivie_heures/mission_ordre/supprimer_ordre.php?id_ordre='+id_ordre).subscribe(data=>{
  
         
          if(data['success']==true)
          {
this.toastr.success("Supprimé avec succes !")
this.liste_mission();
          }
          else
          {
            this.toastr.success("Erreur de suppression !")
          }
         
       
         
      
      })

      } else if (result.isDenied) {
        
      }
    })
    
  }
  affiche(id_ordre)
  {
    //alert(id_ordre)
    
    const modalRef = this.modalService.open(modal_pdf);
    modalRef.componentInstance.id_ordre = id_ordre;
    modalRef.result.then(
        (result) => {
     this.liste_mission();
        },
        (reason) => {
           
           if(reason==undefined)
           {
            this.liste_mission()
           }
        }
      );

  }

  liste_mission()
  {
    this.http.get(this.base_url+'/suivie_heures/mission_ordre/liste_ordre.php').subscribe(data=>{
  
      this.data_ordre=data;
      console.log(data);
     
   
     
  
  })
  }

}

@Component({
  template: `
  <div class="modal-content" style="width:150% !important">
    <div  class="modal-header"  >
    <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Chargement...</p>
  </ngx-spinner>
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i>  ORDRE DE MISSION  </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body"  >


<hr>
<div class="row">

<div class="col-2">
  
</div>
<div class="col-2">
MISSION  
</div>
  <div class="col-6" align="center">
  
  <select class="form-control" [(ngModel)]="id_mission_ord" (change)="chang_mis()">
  <option value="0"></option>
  <option *ngFor="let item of data_mission; let i=index" value="{{item.id_mission}}" >
 
{{item.date_debut}}: {{item.nom_ville}} {{item.nom_comm}}
  </option>

  </select>

  </div>

  <div class="col-2">
  
</div>

</div>
<hr/>
<br/>

   <div class="row">
     <div class="col-2">
     Nom   
     </div>
     <div class="col-4">
    
    <select class="form-control" [(ngModel)]="id_pilote">
    <option value="0"></option>
    <option  *ngFor="let item of data_pilote; let i=index" value="{{item.id_pilote}}">
    {{item.nom_pilote}} {{item.prenom_pilote}}
    </option>

    </select>
     </div>
     <div class="col-2">
     Fonction 
     </div>
     <div class="col-4">
     <select class="form-control" [(ngModel)]="fonction_ordre">
     <option  value="TELEPILOTE">
     TELEPILOTE
     </option>
     <option  value="CHAUFFEUR">
     CHAUFFEUR
     </option>
 
     </select>
      </div>
    
   </div>
   <br/>
   <div class="row">
   
   

  
   <div class="col-2">
   Objet Mission 
   </div>
   <div class="col-4">
  <input [(ngModel)]="objet_ordre" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
   </div>
   <div class="col-2">
   Transport
   </div>
   <div class="col-4">
   <select class="form-control"  [ngModelOptions]="{standalone: true}" [(ngModel)]="transport_ordre">
   <option value=""></option>
<option value="CAR"> CAR</option>
<option value=" FLOTTE DRONE ACADEMIE">FLOTTE DRONE ACADEMIE</option>
<option value="FLOTTE VEHICULE ATL">FLOTTE VEHICULE ATL</option>

</select>
   </div>
  
 </div>
 <br/>
 <div class="row">
 <div class="col-2">
 Drone 1
 </div>
 <div class="col-4">
 <select class="form-control" [(ngModel)]="id_drone1" (change)="chang_drone()">
 <option value="0">...</option>
 <option  *ngFor="let item of data_drone; let i=index" value="{{item.id_drone}}">
 {{item.libelle_drone}} 
 </option>

 </select>

 </div>

 <div class="col-2">

 Drone 2
 </div>
 <div class="col-4">

 <select class="form-control" [(ngModel)]="id_drone2" (change)="chang_drone()">
 <option value="0">...</option>
 <option  *ngFor="let item of data_drone; let i=index" value="{{item.id_drone}}">
 {{item.libelle_drone}} 
 </option>

 </select>
 </div>


</div>


<br/>
<div class="row">
<div class="col-2">
Date Depart 
</div>
<div class="col-4">
<input [(ngModel)]="depart_ordre" [ngModelOptions]="{standalone: true}" (change)="calcul_date()" type="date" class="form-control" />
</div>
<div class="col-2">
Date Retour
</div>
<div class="col-4">
<input [(ngModel)]="retour_ordre" [ngModelOptions]="{standalone: true}" (change)="calcul_date()" type="date" class="form-control" />
</div>

</div>

<hr>

<div class="row">
<div class="col-4" align="center">
<b>HOTEL </b>
</div>

<div class="col-4" align="center">
<b>RESTAURATION</b>
</div>
<div class="col-4" align="center"> 
<b>PERDIEM</b>
</div>

</div>
<br/>
<div class="row">
<div class="col-4" align="center">
Prix
<input [(ngModel)]="prix_hotel_ordre" (keyup)="calcul_hotel()" [ngModelOptions]="{standalone: true}" type="number" class="form-control" />
</div>

<div class="col-4" align="center">
Prix
<input [(ngModel)]="prix_rest_ordre" (keyup)="calcul_rest()" [ngModelOptions]="{standalone: true}" type="number" class="form-control" />
</div>
<div class="col-4" align="center"> 
Prix
<input [(ngModel)]="prix_perdiem_ordre" (keyup)="calcul_perd()" [ngModelOptions]="{standalone: true}" type="number" class="form-control" />
</div>

</div>

<br/>
<div class="row">
<div class="col-4" align="center">
NB jours
<input [(ngModel)]="nbjours_hotel_ordre" (keyup)="calcul_hotel()" [ngModelOptions]="{standalone: true}" type="number" class="form-control" />
</div>

<div class="col-4" align="center">
NB jours
<input [(ngModel)]="nbjours_rest_ordre" (keyup)="calcul_rest()" [ngModelOptions]="{standalone: true}" type="number" class="form-control" />
</div>
<div class="col-4" align="center"> 
NB jours
<input [(ngModel)]="nbjours_perdiem_ordre" (keyup)="calcul_perd()" [ngModelOptions]="{standalone: true}" type="number" class="form-control" />
</div>

</div>

<br/>
<div class="row">
<div class="col-4" align="center">
Total
<input [(ngModel)]="total_hotel_ordre" disabled="true" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
</div>

<div class="col-4" align="center">
Total
<input [(ngModel)]="total_rest_ordre" disabled="true" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
</div>
<div class="col-4" align="center"> 
Total
<input [(ngModel)]="total_perdiem_ordre" disabled="true" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
</div>

</div>
<hr>



<div class="row">
<div class="col-4" align="center">


</div>
<div class="col-4" align="center">


</div>
<div class="col-4" align="center">

Signataire
<select class="form-control" [(ngModel)]="signataire">
<option value="">


</option>
<option value="Le President">
Le President

</option>
<option value="Le Directeur">
Le Directeur

</option>

</select>
</div>



</div>
  
  
  
  



   
      </div>
      
   
  
  
  
    
    
   
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="ajout()">Valider</button>
    </div>
    </div>
  `
})
export class modalCreer_ordremission {
  @Input() public id_drone;
  test_data: any;
  prix_hotel_ordre : any=0;
  prix_rest_ordre : any=0;
  prix_perdiem_ordre : any=0;
  nbjours_hotel_ordre : any=0;
  id_mission_ord : any=0;
  nbjours_rest_ordre : any=0;
  nbjours_perdiem_ordre : any=0;
  total_hotel_ordre : any=0;
  total_rest_ordre : any=0;
  total_perdiem_ordre : any=0;
  libelle_drone:any='';
  objet_ordre : any="";
  data_mission : any= [];
  data_drone : any=[];
  data_pilote : any=[];
  signataire : any="";
  transport_ordre : any="";
  fonction_ordre : any="TELEPILOTE";
  base_url : any='https://africantechnolab.com';
  depart_ordre : any='';
  retour_ordre : any='';
  id_pilote: any=0;
  id_drone1 :any=0;
  id_drone2: any=0;
  id_ville : any=0;
  id_commune:any=0;
  id_drones : any=[];
  constructor(
 
  private modalService:  NgbModal,
   public activeModal: NgbActiveModal,
   private toastr: ToastrService, private http : HttpClient) {
      //this.id_ut=this.storage.get('id_ut_form');
      
this.liste_mission();
this.liste_pilote();
 
this.liste_drone();

  }
  chang_mis()
  {
 
    this.http.get(this.base_url+'/suivie_heures/mission_ordre/liste_mission_id.php?id_mission='+this.id_mission_ord).subscribe(data=>{
    
      this.retour_ordre=data[0].date_fin;
      this.depart_ordre=data[0].date_debut;
      
   this.calcul_date();
     
  
  }) 
  }
  chang_drone()
  {
    if(this.id_drone1==0 || this.id_drone2==0)
    {

    }
    else
    {
      if(this.id_drone1==this.id_drone2)
      {
  this.id_drone1=0;
  this.id_drone2=0;
 
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Veuillez choisir des drones différents svp !',
   
  })
      } 
    }

  

  }
  calcul_date()
  {
    if(this.depart_ordre=='' || this.retour_ordre=='')
    {

    }
    else
    {
      var date1 = new Date(this.depart_ordre);
      var date2 = new Date(this.retour_ordre); 
      var Time = date2.getTime() - date1.getTime(); 
      var Days = Time / (1000 * 3600 * 24); //Diference in Days
      this.nbjours_hotel_ordre=Days
      this.nbjours_perdiem_ordre=Days
      this.nbjours_rest_ordre=Days
    }

  }
  calcul_rest()
  {
    
    this.total_rest_ordre=this.prix_rest_ordre * this.nbjours_rest_ordre;
  }
  calcul_perd()
  {
    
    this.total_perdiem_ordre=this.prix_perdiem_ordre * this.nbjours_perdiem_ordre;
  }
  calcul_hotel()
  {
    
    this.total_hotel_ordre=this.prix_hotel_ordre * this.nbjours_hotel_ordre;
  }
  liste_pilote()
  {
    this.http.get(this.base_url+'/suivie_heures/pilote/liste_pilote.php').subscribe(data=>{
      let ty : any=[]
      ty=data;
      this.data_pilote=data
    

    })

  }
  liste_drone()
  {
    this.http.get(this.base_url+'/suivie_heures/drone/liste_drone.php').subscribe(data=>{
    
      this.data_drone=data
  
 
      
     
   
     
  
  }) 
  }
  liste_mission()
  {
    this.http.get(this.base_url+'/suivie_heures/mission_ordre/liste_mission.php').subscribe(data=>{
  
      this.data_mission=data;
      console.log(data);
     
   
     
  
  }) 

  }
  ngOnInit(): void {
      //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
      //Add 'implements OnInit' to the class.
     
      if(this.id_drone!=undefined)
      {
this.get_info(this.id_drone)
      }
  }

  get_info(id_drone)
  {
      this.http.get(this.base_url+'/suivie_heures/drone/get_drone.php?id_drone='+id_drone).subscribe(data=>{

          this.data_drone=data;
          this.libelle_drone=this.data_drone[0].libelle_drone;
        
          
         
         
         

      })
  }


  ajout()
  {
  
     if(this.signataire=='' || this.id_mission_ord==0 ||  this.fonction_ordre=="" ||  this.id_pilote==0 || this.objet_ordre=="" || this.transport_ordre=="" || (this.id_drone1==0 && this.id_drone2==0) || this.retour_ordre=="" || this.depart_ordre=="" || this.prix_hotel_ordre==0 || this.nbjours_hotel_ordre==0 ||  this.prix_rest_ordre==0 || this.nbjours_rest_ordre==0 || this.total_hotel_ordre==0 ||  this.total_rest_ordre==0 )
     {
     
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Champ(s) Vide(s) /  incorrecte',
       
      })
     }
     else
     {
      let postData = new FormData();
      this.id_drones=[];
      //alert(this.libelle)
          if(this.id_drone1!=0)
          {
            this.id_drones.push(this.id_drone1);
          }
          if(this.id_drone2!=0)
          {
            this.id_drones.push(this.id_drone2);
          }

          postData.append('id_mission',this.id_mission_ord );
          postData.append('signataire',this.signataire );
          postData.append('id_pilote',this.id_pilote );
          postData.append('id_ville',this.id_ville );
          postData.append('id_comm',this.id_commune );
          postData.append('objet_ordre',this.objet_ordre );
          postData.append('transport_ordre',this.transport_ordre );
          postData.append('depart_ordre',this.depart_ordre );
          postData.append('retour_ordre',this.retour_ordre );
          postData.append('id_drone',this.id_drones );
          postData.append('id_drone1',this.id_drone1 );
          postData.append('id_drone2',this.id_drone2 );
         
          postData.append('prix_hotel_ordre',this.prix_hotel_ordre);
          postData.append('nbjours_hotel_ordre',this.nbjours_hotel_ordre);
          postData.append('total_hotel_ordre',this.total_hotel_ordre);
          postData.append('prix_rest_ordre',this.prix_rest_ordre);
          postData.append('nbjours_rest_ordre',this.nbjours_rest_ordre);
          postData.append('total_rest_ordre',this.total_rest_ordre);
          postData.append('prix_perdiem_ordre',this.prix_perdiem_ordre);
          postData.append('nbjours_perdiem_ordre',this.nbjours_perdiem_ordre);
          postData.append('total_perdiem_ordre',this.total_perdiem_ordre);
          postData.append('fonction_ordre',this.fonction_ordre);
          
         
          this.http.post(this.base_url+'/suivie_heures/mission_ordre/add_ordre.php', postData).subscribe(data=>{
    
          
           if(data['success']==true)
           {
              
            console.log(data);
          this.toastr.success('Ordre de mission Ajoutée avec succès !')
         // this.liste_cat()
           this.modalService.dismissAll()
          }
          else
          {
              
              this.toastr.error('Erreur innatendue !')
              
              
    
          }
      
      
      
      })

     }
      
  }
}


@Component({
  template: `
  <div class="modal-content" style="width:150% !important; height:20% !important">
    <div  class="modal-header"  >
    <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    
  </ngx-spinner>
    <h4 class="modal-title " style="text-align:center !important">
    <i class="la la-eye"></i>  ORDRE DE MISSION  </h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" id="contentToConvert"   >
    <div >

    </div>

    <div  #pdfTable style="font-family: Calibri(Body) !important; font-size:16px;color:#0099cc">
    
    <div align="center">
    <div  style="height: 5% !important ;width: 90% !important;border-style: solid; border-color:#0099cc;background-color:#0099cc">
    

    </div>
    </div>
    <br/>
  
      <div class="row">
      <div class="col-4" align="center">
       
      <div class="logo-image-small">
      <img src="assets/img/logo.png" height="110px">
    </div>

      </div>
      <div class="col-2">
       
        

      </div>
      <div class="col-5" style="border-style: solid; border-color:#0099cc" align="center">

      <b style="font-size:26px !important;color:#0099cc"><u>Ordre de Mission</u></b><br/>
      <br/>
      <b style="font-size:20px !important;color:#0099cc"><u>{{data_ordre[0]['numero_ordre']}}</u></b>
      </div>
       
    
      </div>
      <br/>
      <div align="center" >
     <div  style="height: 10px !important ;width: 90% !important;border-style: solid; border-color:#0099cc;background-color:#0099cc">
      

      </div>
      </div>
      
      <div class="row">
      <div class="col-1">

      </div>

      <div class="col-11" style="font-size:16px !important">
      <br/>
      <p>Nom : {{data_ordre[0]['nom_pilote']}}  </p>
      <p>Prenom : {{data_ordre[0]['prenom_pilote']}} </p>
      <p>Fonction : {{data_ordre[0]['fonction_ordre']}} </p>
      <p>Destination : {{data_ordre[0]['nom_ville']}} {{data_ordre[0]['nom_comm']}} </p>
      <p>Objet de la mission : {{data_ordre[0]['objet_ordre']}}</p>
      <p>Drones Utilisés : {{drone1}} | {{drone2}}  </p>
      <p>Moyen de Transport : {{data_ordre[0]['id_trp']}}</p>
      <p>Date :  Départ -> {{depart_date}} | Retour-> {{retour_date}} </p>


          </div>

      </div>

      <div class="row" align="center" >
  
      <div class="col-12" >
      <p style="font-size:20px"><b><u>Frais de Mission</u></b></p>

      <table  class="" border="1"  style="border-color: white;width:650px;background-color:#0099cc; height:100px !important" >

      <tr style="color: white;">
      <td width="25%" >
        Designation
      </td>


      <td>
      Montant

      </td>
      <td width="25%">
        Nombre de Jour
      </td>


      <td>
      Total
      </td>


      </tr>

      <tr style="color: white" >
      <td style="color: white"  >
        Hôtel
      </td>


      <td >
      
      {{data_ordre[0]['prix_hotel_ordre']}} CFA
      </td>
      <td>
      
      {{data_ordre[0]['nbjours_rest_ordre']}} 
      </td>


      <td>
      {{data_ordre[0]['total_hotel_ordre']}} CFA
      </td>


      </tr>

      <tr  style="color: white">
      <td style="color: white"  >
        Restauration
      </td>


      <td>
      {{data_ordre[0]['prix_rest_ordre']}} CFA

      </td>
      <td>
      {{data_ordre[0]['nbjours_rest_ordre']}}
      </td>


      <td>
      {{data_ordre[0]['total_rest_ordre']}} CFA
      </td>


      </tr>

      <tr  style="color: white">
      <td style="color: white" >
        Per diem week-end
      </td>


      <td>
      
      {{data_ordre[0]['prix_perdiem_ordre']}} CFA
      </td>
      <td>
      {{data_ordre[0]['nbjours_perdiem_ordre']}}
      </td>


      <td>
      {{data_ordre[0]['total_perdiem_ordre']}} CFA
      </td>


      </tr>
      <tr  style="color: white">
        <td colspan="3" align="left">

            


        </td>
        <td>
        {{total }}  CFA

        </td>


      </tr>
      

      </table>

      <div>
      

   </div> 
   <br/>
  
   <div class="row">
    <div class="col-4">


    </div>
    <div class="col-8">

    <p>Fait à : ……………………………, Le ……………………….</p>
   
    </div>


   </div>
   <div class="row">
   <div class="col-4">


   </div>
   <div class="col-4">

   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{data_ordre[0]['signataire']}}
   <br/>
    <br/>
  
   </div>


  </div>
  

    </div>
    <br/>
    <br/>

    <div class="row" >
    <div class="col-4">

    </div>
     

      <div class="col-8" >

      <table  class="" border="1"  style="border-color: #0099cc;width:400px;background-color:white; height:100px !important" >

      <tr align="center" valign="top">
      <td>
      Signature :
      </td>

      <td>
      Signature Telepilote:
      </td>

      </tr>

      </table>
    
      </div>

     

    </div>

   
   
   


   
      </div>
      
      <br/>
      <br/>
      <div align="center">
      <div  style="height: 5% !important ;width: 90% !important;border-style: solid; border-color:#0099cc;background-color:#0099cc">
      
  
      </div>
     
      </div>
      
  </div>
  <div align="center">
  <p style="color:silver"><b style="color:red !important">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AFRICAN TECHNO LAB</b> - 25 BP 1423 Abidjan --Côte d'Ivoire-- Tel(225)57866510 
  
  &nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp; SAS au Capital de 20.000.000 FCFA --RCCM CI-ABJ-2017-B-15715-- CC 1728465E</p>
  <p></p>
  </div>
  </div>
  

    
   
   
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
      <button type="button" class="btn btn-outline-dark" (click)="ajout()">TELECHARGER</button>
    </div>
    </div>
  `
})
export class modal_pdf {
  @Input() public id_ordre;
  title = 'htmltopdf';
  
  @ViewChild('pdfTable') pdfTable: ElementRef;
  test_data: any;
  prix_hotel_ordre : any=0;
  prix_rest_ordre : any=0;
  prix_perdiem_ordre : any=0;
  nbjours_hotel_ordre : any=0;
  id_mission_ord : any=0;
  nbjours_rest_ordre : any=0;
  nbjours_perdiem_ordre : any=0;
  total_hotel_ordre : any=0;
  total_rest_ordre : any=0;
  total_perdiem_ordre : any=0;
  libelle_drone:any='';
  objet_ordre : any="";
  data_mission : any= [];
  data_drone : any=[];
  data_pilote : any=[];
  transport_ordre : any="";
  fonction_ordre : any="TELEPILOTE";
  base_url : any='https://africantechnolab.com';
  depart_ordre : any='';
  retour_ordre : any='';
  id_pilote: any=0;
  id_drone1 :any=0;
  id_drone2: any=0;
  id_ville : any=0;
  id_commune:any=0;
  data_ordre : any=[];
  total : any=0;
  drone1 :any="";
  drone2:any="";
  depart_date : any;
  retour_date : any;
  
  constructor(
    private datePipe : DatePipe,
  private modalService:  NgbModal,
   public activeModal: NgbActiveModal,
   private toastr: ToastrService, private http : HttpClient) {
      //this.id_ut=this.storage.get('id_ut_form');
      


  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.affiche(this.id_ordre);
    this.affiche_drones(this.id_ordre);
  }
  affiche_drones(id_ordre)
  {
    this.http.get(this.base_url+'/suivie_heures/mission_ordre/affiche_drone.php?id_ordre='+id_ordre).subscribe(data=>{
  
      this.data_drone=data;
      console.log(data);
      
     this.drone1=this.data_drone[0]['libelle_drone'];
     this.drone2=this.data_drone[1]['libelle_drone'];
   
     
  
  })

  }
  affiche(id)
  {

    //alert(id);
    this.http.get(this.base_url+'/suivie_heures/mission_ordre/affiche_ordre.php?id_ordre='+id).subscribe(data=>{
  
      this.data_ordre=data;
      this.total= parseInt(this.data_ordre[0]['total_hotel_ordre']) + parseInt(this.data_ordre[0]['total_perdiem_ordre']) + parseInt(this.data_ordre[0]['total_rest_ordre']);
      console.log(data);
      //let date_mvt=
      this.depart_date=this.datePipe.transform(this.data_ordre[0]['depart_ordre'],"dd/MM/yyyy");
      this.retour_date=this.datePipe.transform(this.data_ordre[0]['retour_ordre'],"dd/MM/yyyy");
     
   
     
  
  })
  }
  ajout()
  {
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
     
  }
  
}
