import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { LocalStorage } from "node-localstorage";
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HttpClientModule } from "@angular/common/http";
//import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { modalCreer_pilote } from "./pages/maps/maps.component";
import { modalCreer_ordremission, modal_pdf } from "./pages/notifications/notifications.component";
import { modalCreer_drone } from "./pages/icons/icons.component";
import { modalCreer_mission } from "./pages/table/table.component";
import { LoginComponent } from './login/login.component';
import { DatePipe } from "@angular/common";


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    modalCreer_pilote,
    modalCreer_mission,
    modalCreer_ordremission,
    modalCreer_drone,
    modal_pdf,
    LoginComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    SidebarModule,
    //NgxSpinnerModule,
   // StorageServiceModule,
 

    NavbarModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
