import { HttpClient } from '@angular/common/http';
import { Component,Inject,Input,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
//import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

declare interface TableData {
    headerRow: string[];
    dataRows: string[][];
}

@Component({
    selector: 'table-cmp',
    moduleId: module.id,
    templateUrl: 'table.component.html'
})

export class TableComponent implements OnInit{
    public tableData1: TableData;
    public tableData2: TableData;
    data_mission:any=[];
      base_url : any='https://africantechnolab.com';
    constructor( private router: Router,
        private http : HttpClient,
        private modalService : NgbModal,
       
        private toastr: ToastrService)
        {

          this.liste_mission();
        }

        creer_mission()
  {
      const modalRef = this.modalService.open(modalCreer_mission);
      modalRef.result.then(
          (result) => {
            this.liste_mission
          },
          (reason) => {
             
             if(reason==undefined)
             {
              this.liste_mission()
             }
          }
        );
  }

  liste_mission()
  {
    this.http.get(this.base_url+'/suivie_heures/mission_ordre/liste_mission.php').subscribe(data=>{
  
      this.data_mission=data;
      console.log(data);
     
   
     
  
  }) 

  }
    ngOnInit(){
        this.tableData1 = {
            headerRow: [ 'ID', 'Name', 'Country', 'City', 'Salary'],
            dataRows: [
                ['1', 'Dakota Rice', 'Niger', 'Oud-Turnhout', '$36,738'],
                ['2', 'Minerva Hooper', 'Curaçao', 'Sinaai-Waas', '$23,789'],
                ['3', 'Sage Rodriguez', 'Netherlands', 'Baileux', '$56,142'],
                ['4', 'Philip Chaney', 'Korea, South', 'Overland Park', '$38,735'],
                ['5', 'Doris Greene', 'Malawi', 'Feldkirchen in Kärnten', '$63,542'],
                ['6', 'Mason Porter', 'Chile', 'Gloucester', '$78,615']
            ]
        };
        this.tableData2 = {
            headerRow: [ 'ID', 'Name',  'Salary', 'Country', 'City' ],
            dataRows: [
                ['1', 'Dakota Rice','$36,738', 'Niger', 'Oud-Turnhout' ],
                ['2', 'Minerva Hooper', '$23,789', 'Curaçao', 'Sinaai-Waas'],
                ['3', 'Sage Rodriguez', '$56,142', 'Netherlands', 'Baileux' ],
                ['4', 'Philip Chaney', '$38,735', 'Korea, South', 'Overland Park' ],
                ['5', 'Doris Greene', '$63,542', 'Malawi', 'Feldkirchen in Kärnten', ],
                ['6', 'Mason Porter', '$78,615', 'Chile', 'Gloucester' ]
            ]
        };
    }
}
@Component({
    template: `
    <div class="modal-content" style="width:150% !important">
      <div  class="modal-header"  >
      <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Chargement...</p>
    </ngx-spinner>
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i>   MISSION  </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"  >
  
  
 
  <br/>
  
     <div class="row">
      
       <div class="col-2">
       Destination
       </div>
       <div class="col-5">
       <select class="form-control" [(ngModel)]="id_ville" (change)="liste_commune()">
       <option value="0">VILLE</option>
       <option *ngFor="let item of data_ville" value="{{item.id_ville}}" > {{item.nom_ville}}</option>
   
       </select>
       </div>
       <div class="col-5">
       <select class="form-control" [(ngModel)]="id_commune" >
       <option value="0">COMMUNE</option>
       <option *ngFor="let item of data_commune" value="{{item.id_comm}}" > {{item.nom_comm}}</option>
   
       </select>
       </div>
      
     </div>
     <br/>
     <div class="row">
     <div class="col-3">
     Date Depart
     </div>
     <div class="col-3">
     <input [(ngModel)]="date_debut" [ngModelOptions]="{standalone: true}" type="date" class="form-control" />
  
     </div>
     <div class="col-3">
    Date Retour
     </div>
     <div class="col-3">
    <input [(ngModel)]="date_fin" [ngModelOptions]="{standalone: true}" type="date" class="form-control" />
     </div>
    
   </div>
   <br/>
  
  
     
        </div>
        
     
    
    
    
      
      
     
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="ajout_mission()">Valider</button>
      </div>
      </div>
    `
  })
  export class modalCreer_mission {
    @Input() public id_drone;
    test_data: any;
    id_destination : any=0;
    libelle_drone:any='';
    data_ville : any=[];
    id_ville : any=0;
    id_commune : any=0;
    data_commune : any=[];
    data_drone : any=[];
    date_debut : any="";
    date_fin : any="";
    base_url : any='https://africantechnolab.com';
    constructor(
   
    private modalService:  NgbModal,
     public activeModal: NgbActiveModal,
     private toastr: ToastrService, private http : HttpClient) {
        //this.id_ut=this.storage.get('id_ut_form');
        
  
   this.liste_ville();
  
  
    }
    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
       
        if(this.id_drone!=undefined)
        {
  this.get_info(this.id_drone)
        }
    }
  
    get_info(id_drone)
    {
        this.http.get(this.base_url+'/suivie_heures/drone/get_drone.php?id_drone='+id_drone).subscribe(data=>{
  
            this.data_drone=data;
            this.libelle_drone=this.data_drone[0].libelle_drone;
          
            
           
           
           
  
        })
    }
  
    liste_ville()
    {
      this.http.get(this.base_url+'/suivie_heures/mission_ordre/liste_ville.php').subscribe(data=>{
  
        this.data_ville=data;
       
      
        
       
       
       

    })

    }
    liste_commune()
    {
   
      this.http.get(this.base_url+'/suivie_heures/mission_ordre/liste_commune.php?id_ville='+this.id_ville).subscribe(data=>{
  
        this.data_commune=data;
       
      
        
       
       
       

    })
    }
  
    ajout_mission()
    {
    
       
      if( this.id_ville=='' || this.id_commune=='' || this.date_debut=='' || this.date_fin=="" )
      {
          this.toastr.error('Champ(s) vide(s)')
      }
      else
  
      {
      //  alert(this.date_debut);
        if(this.date_fin<this.date_debut)
        {
          alert('Dates incorrectes');
        }
        else
        {
          
          let postData = new FormData();
          //alert(this.libelle)
       
              postData.append('id_ville' , this.id_ville);
              postData.append('id_comm' , this.id_commune);
              postData.append('date_debut' , this.date_debut);
              postData.append('date_fin' , this.date_fin);
              
             
              this.http.post(this.base_url+'/suivie_heures/mission_ordre/add_mission.php', postData).subscribe(data=>{
        
              
               if(data['success']==true)
               {
                  
                
              this.toastr.success('Mission Ajoutée avec succès !')
             // this.liste_cat()
              this.modalService.dismissAll()
              }
              else
              {
                  
                  this.toastr.error('Erreur innatendue !')
                  
                  
        
              }
          
          
          
          })
          
        }
      
 
 
     
      }
           
           
  
        
    }
  }
