import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from "node-localstorage";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent{

  constructor(private router : Router)
  {
    if(localStorage.getItem('id_ut_form')==undefined)
    {
      this.router.navigate(['login'])
    }
    else
    {
      this.router.navigate(['notifications'])
    }
  }
}
