import { HttpClient } from '@angular/common/http';
import { Component,Inject,Input,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'icons-cmp',
    moduleId: module.id,
    templateUrl: 'icons.component.html'
})

export class IconsComponent{

    base_url : any='https://africantechnolab.com'
    data_drone : any=[];
    data_mission : any=[]
    data_id_mission : any=[]

    constructor( private router: Router,
        private http : HttpClient,
        private modalService : NgbModal,
      
        private toastr: ToastrService)
    {
      this.liste_drone()

    }

    creer_drone()
    {
        const modalRef = this.modalService.open(modalCreer_drone);
        modalRef.result.then(
            (result) => {
         
            },
            (reason) => {
               
               if(reason==undefined)
               {
                this.liste_drone()
               }
            }
          );
    }
    modifier_drone(id)
    {
      const modalRef = this.modalService.open(modalCreer_drone);
      modalRef.componentInstance.id_drone =id
      modalRef.result.then(
          (result) => {
          
          },
          (reason) => {
          
             if(reason==undefined)
             {
              this.liste_drone()
             }
          }
        );
    }
    supprimer_drone(id)
    {
      if (confirm("Vous vraiment retirer ce Drone ?" )) {
    
        this.http.get(this.base_url+'/suivie_admin/drone/verif_drone.php?id_drone='+id).subscribe(data=>{
    
    if(data['success']==true)
             {
               //Verifier sil a des notes
    
               if (confirm('Ce Drone est lié à des missions, voulez vous vraiment le supprimer ?'))
                {
    
                  let postData = new FormData();
                  postData.append('id_drone' , id);
    
                  this.http.post(this.base_url+'/suivie_admin/drone/supprimer_drone.php', postData).subscribe(data=>{
              
              if(data['success']==true)
                       {
                        
                      this.toastr.success('Drone retiré avec succès !')
                     this.liste_drone()
                    
                      }
                      else
                      {
                          
                          this.toastr.error('Erreur /Drone déja retiré !')
                          
                          
              
                      }
              })
    
               }
               else
    
               {
                this.toastr.error('Action annulée')
               }
              
          
           // this.liste_cat()
          
            }
            else
            {
                
                //supprimer
                let postData = new FormData();
                postData.append('id_drone' , id);
              
               
                this.http.post(this.base_url+'/suivie_admin/drone/supprimer_drone.php', postData).subscribe(data=>{
            
            if(data['success']==true)
                     {
                      
                    this.toastr.success('Drone retiré avec succès !')
                   this.liste_drone()
                  
                    }
                    else
                    {
                        
                        this.toastr.error('Erreur /Drone déja retiré !')
                       
                        
            
                    }
            })
                
    
            }
    })
      }
    }
    ouvrir_drone(id)
    {
    
    }
    liste_drone()
    {
      this.http.get(this.base_url+'/suivie_heures/mission_ordre/liste_drone.php').subscribe(data=>{
      
        this.data_drone=data;
    
     
        
       
     
       
    
    }) 
    }

}
@Component({
    template: `
      <div  class="modal-header" >
      <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Chargement...</p>
    </ngx-spinner>
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i>  Drone  </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     <div class="row">
       <div class="col-6">
       Libelle 
       </div>
       <div class="col-6">
      <input [(ngModel)]="libelle_drone" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
       </div>
      
     </div>
    
  
 
 
     
        
        
     
    
    
    
      
      
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="ajout_drone()">Valider</button>
      </div>
    `
  })
  export class modalCreer_drone {
    @Input() public id_drone;
    test_data: any;
   
    libelle_drone:any='';
   
    data_drone : any=[];
    base_url : any='https://africantechnolab.com';
    constructor(
  
    private modalService:  NgbModal,
     public activeModal: NgbActiveModal,
     private toastr: ToastrService, private http : HttpClient) {
        //this.id_ut=this.storage.get('id_ut_form');
        
  
   


    }
    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
       
        if(this.id_drone!=undefined)
        {
this.get_info(this.id_drone)
        }
    }

    get_info(id_drone)
    {
        this.http.get(this.base_url+'/suivie_heures/drone/get_drone.php?id_drone='+id_drone).subscribe(data=>{
  
            this.data_drone=data;
            this.libelle_drone=this.data_drone[0].libelle_drone;
          
            
           
           
           
  
        })
    }
  
  
    ajout_drone()
    {
    
       
      if( this.libelle_drone=='' )
      {
          this.toastr.error('Champ(s) vide(s)')
      }
      else
  
      {
      
        let postData = new FormData();
        //alert(this.libelle)
        if(this.id_drone==undefined)
        {
            postData.append('id_drone' , 'rien');
            postData.append('libelle_drone' , this.libelle_drone);
            
           
            this.http.post(this.base_url+'/suivie_heures/drone/ajouter_drone.php', postData).subscribe(data=>{
      
            
             if(data['success']==true)
             {
                
              
            this.toastr.success('Drone Ajouté avec succès !')
           // this.liste_cat()
            this.modalService.dismissAll()
            }
            else
            {
                
                this.toastr.error('Erreur ! Un Autre drone porte les memes noms!')
               
                
      
            }
        
        
        
        })
        }
        else
        {
            postData.append('id_drone' , this.id_drone);
            postData.append('libelle_drone' , this.libelle_drone);
           
         
           
            this.http.post(this.base_url+'/suivie_heures/drone/ajouter_drone.php', postData).subscribe(data=>{
      
         
             if(data['success']==true)
             {
                
               
            this.toastr.success('Drone Modifié avec succès !')
           // this.liste_cat()
            this.modalService.dismissAll()
            }
            else
            {
                
                this.toastr.error('Erreur ! Un Autre Drone porte les memes noms!')
             
                
      
            }
        
        
        
        })
        }
     
      }
           
           
  
        
    }
  }