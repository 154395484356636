import { Component,Inject,Input,OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var google: any;

@Component({
    moduleId: module.id,
    selector: 'maps-cmp',
    templateUrl: 'maps.component.html'
})

export class MapsComponent implements OnInit {

    data_pilote : any=[];
base_url : any='https://africantechnolab.com'
  data_id_mission: any=[];
  data_mission: any=[];
  constructor(  private router: Router,
    private http : HttpClient,
    private modalService : NgbModal,
    
    private toastr: ToastrService,
  )
{

this.liste_pilote()
}
modifier_pilote(id)
{
  const modalRef = this.modalService.open(modalCreer_pilote);
  modalRef.componentInstance.id_pilote =id
  modalRef.result.then(
      (result) => {
      
      },
      (reason) => {
      
         if(reason==undefined)
         {
          this.liste_pilote()
         }
      }
    );
}
supprimer_pilote(id)
{
  if (confirm("Vous vraiment retirer ce Pilote ?" )) {

    this.http.get(this.base_url+'/suivie_admin/pilote/verif_pilote.php?id_pilote='+id).subscribe(data=>{

if(data['success']==true)
         {
           //Verifier sil a des notes

           if (confirm('Ce Pilote est lié à des missions, voulez vous vraiment le supprimer ?'))
            {

              let postData = new FormData();
              postData.append('id_pilote' , id);

              this.http.post(this.base_url+'/suivie_admin/pilote/supprimer_pilote.php', postData).subscribe(data=>{
          
          if(data['success']==true)
                   {
                    
                  this.toastr.success('Pilote retiré avec succès !')
                 this.liste_pilote()
                
                  }
                  else
                  {
                      
                      this.toastr.error('Erreur /Pilote déja retiré !')
                     
                      
          
                  }
          })

           }
           else

           {
            this.toastr.error('Action annulée')
           }
          
      
       // this.liste_cat()
      
        }
        else
        {
            
            //supprimer
            let postData = new FormData();
            postData.append('id_part' , id);
          
           
            this.http.post(this.base_url+'/suivie_admin/pilote/supprimer_pilote.php', postData).subscribe(data=>{
        
        if(data['success']==true)
                 {
                  
                this.toastr.success('Pilote retiré avec succès !')
               this.liste_pilote()
              
                }
                else
                {
                    
                    this.toastr.error('Erreur /Pilote déja retiré !')
                   
                    
        
                }
        })
            

        }
})
  }
}
creer_pilote()
{
    const modalRef = this.modalService.open(modalCreer_pilote);
    modalRef.result.then(
        (result) => {
     
        },
        (reason) => {
           
           if(reason==undefined)
           {
            this.liste_pilote()
           }
        }
      );

}
liste_pilote()
{
  this.http.get(this.base_url+'/suivie_heures/mission_ordre/liste_pilote.php').subscribe(data=>{
    let ty : any=[]
    ty=data;
    this.data_pilote=data
 
 
   

}) 
}
    ngOnInit() {
        var myLatlng = new google.maps.LatLng(40.748817, -73.985428);
        var mapOptions = {
          zoom: 13,
          center: myLatlng,
          scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
          styles: [{"featureType":"water","stylers":[{"saturation":43},{"lightness":-11},{"hue":"#0088ff"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"hue":"#ff0000"},{"saturation":-100},{"lightness":99}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"color":"#808080"},{"lightness":54}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ece2d9"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#ccdca1"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#767676"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","stylers":[{"visibility":"off"}]},{"featureType":"landscape.natural","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#b8cb93"}]},{"featureType":"poi.park","stylers":[{"visibility":"on"}]},{"featureType":"poi.sports_complex","stylers":[{"visibility":"on"}]},{"featureType":"poi.medical","stylers":[{"visibility":"on"}]},{"featureType":"poi.business","stylers":[{"visibility":"simplified"}]}]

        }
        var map = new google.maps.Map(document.getElementById("map"), mapOptions);

        var marker = new google.maps.Marker({
            position: myLatlng,
            title:"Hello World!"
        });

        // To add the marker to the map, call setMap();
        marker.setMap(map);
    }
}
@Component({
    template: `
      <div  class="modal-header" >
      <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Chargement...</p>
    </ngx-spinner>
      <h4 class="modal-title " style="text-align:center !important">
      <i class="la la-eye"></i>  Pilote  </h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
     <div class="row">
       <div class="col-6">
       Nom 
       </div>
       <div class="col-6">
      <input [(ngModel)]="nom_pilote" [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
       </div>
      
     </div>
     <div class="row">
     <div class="col-6">
     Prenom 
     </div>
     <div class="col-6">
    <input type="text" [(ngModel)]="prenom_pilote" [ngModelOptions]="{standalone: true}" class="form-control" />
     </div>
    
   </div>
  
 
 
     
        
        
     
    
    
    
      
      
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
        <button type="button" class="btn btn-outline-dark" (click)="ajout_pilote()">Valider</button>
      </div>
    `
  })
  export class modalCreer_pilote {
    @Input() public id_pilote;
    test_data: any;
   
    nom_pilote:any='';
    prenom_pilote:any='';
   
    data_pilote : any=[];
    base_url : any='https://africantechnolab.com';
    constructor(
  
    private modalService:  NgbModal,
     public activeModal: NgbActiveModal,
     private toastr: ToastrService, private http : HttpClient) {
        //this.id_ut=this.storage.get('id_ut_form');
        
  
   


    }
    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
       
        if(this.id_pilote!=undefined)
        {
this.get_info(this.id_pilote)
        }
    }

    get_info(id_pilote)
    {
        this.http.get(this.base_url+'/suivie_heures/pilote/get_pilote.php?id_pilote='+id_pilote).subscribe(data=>{
  
            this.data_pilote=data;
            this.nom_pilote=this.data_pilote[0].nom_pilote;
            this.prenom_pilote=this.data_pilote[0].prenom_pilote
        
            
           
           
           
  
        })
    }
  
  
    ajout_pilote()
    {
    
       
      if( this.nom_pilote=='' || this.prenom_pilote=='')
      {
          this.toastr.error('Champ(s) vide(s)')
      }
      else
  
      {
        
     
        let postData = new FormData();
        //alert(this.libelle)
        if(this.id_pilote==undefined)
        {
            postData.append('id_pilote' , 'rien');
            postData.append('nom_pilote' , this.nom_pilote);
            postData.append('prenom_pilote' , this.prenom_pilote);
           
           
            this.http.post(this.base_url+'/suivie_heures/pilote/ajouter_pilote.php', postData).subscribe(data=>{
      
            
             if(data['success']==true)
             {
                
                
            this.toastr.success('Pilote Ajouté avec succès !')
           // this.liste_cat()
            this.modalService.dismissAll()
            }
            else
            {
              alert(data['success'])
                
                this.toastr.error('Erreur ! Un Autre Pilote porte les memes noms!')
             
                
      
            }
        
        
        
        })
        }
        else
        {
            postData.append('id_pilote' , this.id_pilote);
            postData.append('nom_pilote' , this.nom_pilote);
            postData.append('prenom_pilote' , this.prenom_pilote);
         
           
            this.http.post(this.base_url+'/suivie_heures/pilote/ajouter_pilote.php', postData).subscribe(data=>{
      
         
             if(data['success']==true)
             {
                
              
            this.toastr.success('Pilote Modifié avec succès !')
           // this.liste_cat()
            this.modalService.dismissAll()
            }
            else
            {
                
                this.toastr.error('Erreur ! Un Autre Pilote porte les memes noms!')
                
                
      
            }
        
        
        
        })
        }
     
      }
           
           
  
        
    }
  }